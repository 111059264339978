type Option = {
  style: 'percent'
  minimumFractionDigits: number
  maximumFractionDigits: number
}

const defaultOptions = {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

const defaultFormatter = new Intl.NumberFormat('en-US', defaultOptions)
const noDecimalsFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const format =
  (formatter: any) =>
  (el: number): string | null | undefined => {
    if (el === null || el === undefined) {
      return el
    }
    if (Number.isNaN(el)) {
      return null
    }
    return formatter.format(el)
  }

const percentageFormat = (
  el: number,
  decimals = false,
): string | null | undefined => {
  if (decimals) {
    return format(noDecimalsFormatter)(el)
  } else {
    return format(defaultFormatter)(el)
  }
}

const customFormat =
  (options: Option) =>
  (el: number): string | null | undefined => {
    const formatter = new Intl.NumberFormat('en-US', {
      ...defaultOptions,
      ...options,
    })
    return format(formatter)(el)
  }

export { percentageFormat as default, customFormat }
